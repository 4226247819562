import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import UnderConstruction from "./UnderConstruction";
import "./App.css";

function App() {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith("de")) {
      setLanguage("de");
    } else {
      setLanguage("en");
    }
  }, []);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "de" : "en"));
  };

  return (
    <div className="App">
      <Helmet>
        <title>
          {language === "en"
            ? "Chess Community for Kids - Under Construction"
            : "Schachgemeinschaft für Kinder - Im Aufbau"}
        </title>
        <meta
          name="description"
          content={
            language === "en"
              ? "Chess Community for Kids - Under Construction. We are working hard to bring you the best chess community for kids. Stay tuned!"
              : "Schach Community für Kinder - Im Aufbau. Wir arbeiten hart daran, Ihnen die beste Schach Community für Kinder zu bieten. Bleiben Sie dran!"
          }
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Website",
              "url": "https://chess-for-kids.com/",
              "sameAs": [
                "https://chess-for-kids.de/"
              ],
              "name": "${
                language === "en"
                  ? "Chess Community for Kids"
                  : "Schachgemeinschaft für Kinder"
              }",
              "description": "${
                language === "en"
                  ? "We are working hard to bring you the best chess community for kids. Stay tuned!"
                  : "Wir arbeiten hart daran, Ihnen die beste Schach Community für Kinder zu bieten. Bleiben Sie dran!"
              }"
            }
          `}
        </script>
      </Helmet>
      <button className="toggle-button" onClick={toggleLanguage}>
        {language === "en" ? "Deutsch" : "English"}
      </button>
      <UnderConstruction language={language} />
    </div>
  );
}

export default App;
