import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = ({ language }) => {
  return (
    <div className="under-construction">
      {language === "en" ? (
        <>
          <h1>Chess Community for Kids</h1>
          <h2>Under Construction</h2>
          <p>
            We are working hard to bring you the best chess community for kids.
            Stay tuned!
          </p>
        </>
      ) : (
        <>
          <h1>Schach Community für Kinder</h1>
          <h2>Im Aufbau</h2>
          <p>
            Wir arbeiten hart daran, Ihnen die beste Schach Community für Kinder
            zu bieten. Bleiben Sie dran!
          </p>
        </>
      )}
    </div>
  );
};

export default UnderConstruction;
